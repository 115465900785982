import {
    DetailsFieldset,
    PersonFieldset,
    SpeciesCategorySelect,
    TShirtSizeSelect,
    detailsSchema,
    personSchema,
    speciesCategorySchema,
    tShirtSizeSchema,
} from "@/components/Registration/index.ts";
import { useRegistrationSpecs } from "@/components/RegistrationSpecsProvider/index.ts";
import { useSession } from "@/components/SessionProvider/index.ts";
import { useCreateRegistrationMutation } from "@/mutations/registration.ts";
import type { SpeciesCategory } from "@/queries/registration-specs.ts";
import { getErrorMessage } from "@/utils/api.ts";
import { dateTimeFormatter, formatCents } from "@/utils/format.ts";
import { zodResolver } from "@hookform/resolvers/zod";
import { LoadingButton } from "@mui/lab";
import {
    Alert,
    Box,
    FormControlLabel,
    Paper,
    Stack,
    Switch,
    Typography,
    debounce,
} from "@mui/material";
import { RhfSwitch } from "mui-rhf-integration";
import { useSnackbar } from "notistack";
import { type ReactNode, useEffect, useMemo } from "react";
import Countdown, { type CountdownRendererFn } from "react-countdown";
import { type DeepPartial, useForm } from "react-hook-form";
import { z } from "zod";
import PublicListingConsent from "./PublicListingConsent.tsx";
import TicketSelection from "./TicketSelection.tsx";

const hideRegistrationDate: boolean = false;

const pluralize = (singular: string, number: number) => (number !== 1 ? `${singular}s` : singular);

const countdownRenderer: CountdownRendererFn = ({ days, hours, minutes, seconds }) => {
    const parts = [];

    if (days > 0) {
        parts.push(`${days} ${pluralize("day", days)}`);
    }

    parts.push(
        [
            hours.toString().padStart(2, "0"),
            minutes.toString().padStart(2, "0"),
            seconds.toString().padStart(2, "0"),
        ].join(":"),
    );

    return parts.join(", ");
};

const baseSchema = z
    .object({
        person: personSchema,
        details: detailsSchema,
        speciesCategoryId: speciesCategorySchema,
        sponsorLevel: z.enum(["silver", "gold"]).nullable(),
        addTShirt: z.boolean().default(false),
        tShirtSize: tShirtSizeSchema.nullable().default(null),
        publicListingConsent: z.enum(["yes", "no"]).transform((value) => value === "yes"),
    })
    .superRefine((value, context) => {
        if ((value.sponsorLevel === "gold" || value.addTShirt) && value.tShirtSize === null) {
            context.addIssue({
                code: z.ZodIssueCode.custom,
                message: "Required",
                path: ["tShirtSize"],
            });
        }
    })
    .transform((value) => {
        const { addTShirt, ...rest } = value;
        return rest;
    });

type FieldValues = z.input<typeof baseSchema>;
type TransformedValues = z.output<typeof baseSchema>;

const defaultValues: DeepPartial<FieldValues> = {
    sponsorLevel: null,
    speciesCategoryId: null,
};

const storageSchema = z.object({
    person: personSchema
        .omit({ dateOfBirth: true })
        .extend({
            dateOfBirth: z.coerce.date(),
        })
        .partial()
        .optional(),
    details: detailsSchema.partial().optional(),
    speciesCategoryId: z
        .object({
            id: z.string(),
            name: z.string(),
            description: z.string(),
        })
        .nullable()
        .optional(),
    sponsorLevel: z.enum(["silver", "gold"]).nullable(),
    addTShirt: z.boolean().optional(),
    tShirtSize: tShirtSizeSchema.nullable().default(null),
    publicListingConsent: z.enum(["yes", "no"]).optional(),
});

const loadStoredDefaultValues = (
    storageKey: string,
    allowedSpeciesCategories: SpeciesCategory[],
) => {
    const rawItem = window.localStorage.getItem(storageKey);

    if (!rawItem) {
        return defaultValues;
    }

    const result = storageSchema.safeParse(JSON.parse(rawItem));

    if (!result.success) {
        return defaultValues;
    }

    return {
        ...result.data,
        speciesCategoryId:
            allowedSpeciesCategories.find(
                (category) => category.id === result.data.speciesCategoryId?.id,
            ) ?? null,
    };
};

const RegistrationPage = (): ReactNode => {
    const session = useSession();
    const registrationSpecs = useRegistrationSpecs();
    const storageKey = `registration_pre_fill_fv6_${session.identity.id}`;
    const defaultValues = useMemo(
        () => loadStoredDefaultValues(storageKey, registrationSpecs.speciesCategories),
        [storageKey, registrationSpecs.speciesCategories],
    );
    const createMutation = useCreateRegistrationMutation();
    const { enqueueSnackbar } = useSnackbar();

    const maxDateOfBirth = useMemo(
        () => registrationSpecs.dates.arrival.minusYears(18),
        [registrationSpecs.dates.arrival],
    );

    const schema = useMemo(() => {
        return baseSchema.superRefine((value, context) => {
            if (!value.person.dateOfBirth.isAfter(maxDateOfBirth)) {
                return;
            }

            context.addIssue({
                code: z.ZodIssueCode.custom,
                path: ["person", "dateOfBirth"],
                message: "You must be 18 years or older as of the first day of the convention",
            });
        });
    }, [maxDateOfBirth]);

    const form = useForm<FieldValues, unknown, TransformedValues>({
        resolver: zodResolver(schema),
        defaultValues,
    });

    useEffect(() => {
        const debouncedSave = debounce((value: DeepPartial<FieldValues>) => {
            window.localStorage.setItem(storageKey, JSON.stringify(value));
        }, 1000);

        const subscriber = form.watch(debouncedSave);

        return () => {
            subscriber.unsubscribe();
        };
    }, [storageKey, form.watch]);

    useEffect(() => {
        const subscriber = form.watch((value, { type, name }) => {
            if (type !== "change" || (name !== "sponsorLevel" && name !== "addTShirt")) {
                return;
            }

            if (value.sponsorLevel !== "gold" && !value.addTShirt) {
                form.setValue("tShirtSize", null);
            }
        });

        return () => {
            subscriber.unsubscribe();
        };
    }, [form.setValue, form.watch]);

    const sponsorLevel = form.watch("sponsorLevel");
    const addTShirt = form.watch("addTShirt");

    const handleSubmit = (values: TransformedValues) => {
        const { speciesCategoryId, ...attributes } = values;

        createMutation.mutate(
            {
                attributes,
                speciesCategoryId,
            },
            {
                onError: (error) => {
                    enqueueSnackbar(getErrorMessage(error), { variant: "error" });
                },
            },
        );
    };

    return (
        <form onSubmit={form.handleSubmit(handleSubmit)} noValidate>
            <Stack spacing={2}>
                <Paper sx={{ p: 2 }}>
                    <Typography variant="h6" mb={2}>
                        Personal Details
                    </Typography>
                    <PersonFieldset
                        control={form.control}
                        name="person"
                        maxDateOfBirth={maxDateOfBirth}
                    />
                </Paper>

                <Paper sx={{ p: 2 }}>
                    <Typography variant="h6" mb={2}>
                        Ticket Selection
                    </Typography>
                    <TicketSelection control={form.control} name="sponsorLevel" />
                </Paper>

                <Paper sx={{ p: 2 }}>
                    <Box sx={{ display: "flex", mb: 2 }}>
                        <Typography variant="h6" mr="auto">
                            T-shirt
                        </Typography>

                        {sponsorLevel !== "gold" ? (
                            <FormControlLabel
                                control={<RhfSwitch control={form.control} name="addTShirt" />}
                                label={`Add for ${formatCents(registrationSpecs.prices.tShirt)}`}
                            />
                        ) : (
                            <FormControlLabel
                                control={<Switch checked disabled />}
                                label="Included"
                            />
                        )}
                    </Box>

                    <TShirtSizeSelect
                        control={form.control}
                        name="tShirtSize"
                        disabled={sponsorLevel !== "gold" && !addTShirt}
                    />
                </Paper>

                <Paper sx={{ p: 2 }}>
                    <Typography variant="h6" mb={2}>
                        Tell us about yourself
                    </Typography>

                    <Stack spacing={2}>
                        <DetailsFieldset control={form.control} name="details" />
                        <SpeciesCategorySelect control={form.control} name="speciesCategoryId" />
                    </Stack>
                </Paper>

                <Paper sx={{ p: 2 }}>
                    <Typography variant="h6" mb={2}>
                        Public Listing Consent
                    </Typography>

                    <PublicListingConsent control={form.control} name="publicListingConsent" />
                </Paper>

                <LoadingButton
                    type="submit"
                    variant="outlined"
                    disabled={registrationSpecs.registration.state !== "open"}
                    loading={createMutation.isPending}
                >
                    {registrationSpecs.registration.state === "closed" ? (
                        hideRegistrationDate ? (
                            "Coming soon"
                        ) : (
                            <Countdown
                                date={registrationSpecs.registration.opensAtLocalized}
                                renderer={countdownRenderer}
                            />
                        )
                    ) : (
                        "Register"
                    )}
                </LoadingButton>

                {registrationSpecs.registration.state === "closed" && !hideRegistrationDate && (
                    <Alert severity="info">
                        Registration will open on{" "}
                        {dateTimeFormatter.format(registrationSpecs.registration.opensAt)}. You can
                        close this tab and come back later, your information will be remembered.
                    </Alert>
                )}
            </Stack>
        </form>
    );
};

export default RegistrationPage;
