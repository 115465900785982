import FullPageSpinner from "@/components/FullPageSpinner/index.ts";
import Scheduler from "@/components/RegistrationSpecsProvider/scheduler.ts";
import { type RegistrationSpecs, useRegistrationSpecsQuery } from "@/queries/registration-specs.ts";
import type { ReactNode } from "react";
import { createContext, useContext, useEffect, useMemo, useRef, useState } from "react";

export type RegistrationState = "closed" | "open" | "over";
export type ReservationState = "closed" | "open";

export type ExtendedRegistrationSpecs = RegistrationSpecs & {
    registration: RegistrationSpecs["registration"] & {
        state: RegistrationState;
        opensAtLocalized: Date;
    };
    reservation: RegistrationSpecs["reservation"] & {
        state: ReservationState;
    };
};

const RegistrationSpecsContext = createContext<ExtendedRegistrationSpecs | null>(null);

type Props = {
    children: ReactNode;
};

const RegistrationSpecsProvider = ({ children }: Props): ReactNode => {
    const registrationSpecsQuery = useRegistrationSpecsQuery();
    const scheduler = useRef(new Scheduler());
    const [registrationState, setRegistrationState] = useState<RegistrationState | null>(null);
    const [reservationState, setReservationState] = useState<ReservationState | null>(null);

    const opensAtLocalized = useMemo(() => {
        if (!registrationSpecsQuery.data) {
            return new Date();
        }

        return new Date(Date.now() + registrationSpecsQuery.data.registration.opensIn);
    }, [registrationSpecsQuery.data]);

    useEffect(() => {
        if (!registrationSpecsQuery.data) {
            return;
        }

        const specs = registrationSpecsQuery.data;

        if (specs.registration.opensIn >= 0) {
            scheduler.current.scheduleTask(specs.registration.opensIn, () => {
                setRegistrationState("open");
            });
        }

        if (specs.registration.closesIn >= 0) {
            scheduler.current.scheduleTask(specs.registration.closesIn, () => {
                setRegistrationState("over");
            });
        }

        if (specs.reservation.closesIn >= 0) {
            scheduler.current.scheduleTask(specs.reservation.closesIn, () => {
                setReservationState("closed");
            });
        }

        setRegistrationState(
            specs.registration.opensIn >= 0
                ? "closed"
                : specs.registration.closesIn < 0
                  ? "closed"
                  : "open",
        );
        setReservationState(specs.reservation.closesIn < 0 ? "closed" : "open");

        return () => {
            scheduler.current.shutdown();
        };
    }, [registrationSpecsQuery.data]);

    const registrationSpecs = useMemo((): ExtendedRegistrationSpecs | null => {
        if (!(registrationSpecsQuery.data && registrationState && reservationState)) {
            return null;
        }

        return {
            ...registrationSpecsQuery.data,
            registration: {
                ...registrationSpecsQuery.data.registration,
                opensAtLocalized,
                state: registrationState,
            },
            reservation: {
                ...registrationSpecsQuery.data.reservation,
                state: reservationState,
            },
        };
    }, [registrationSpecsQuery.data, registrationState, reservationState, opensAtLocalized]);

    if (registrationSpecsQuery.isError) {
        throw registrationSpecsQuery.error;
    }

    if (!registrationSpecs) {
        return <FullPageSpinner />;
    }

    return (
        <RegistrationSpecsContext.Provider value={registrationSpecs}>
            {children}
        </RegistrationSpecsContext.Provider>
    );
};

export const useRegistrationSpecs = (): ExtendedRegistrationSpecs => {
    const context = useContext(RegistrationSpecsContext);

    if (!context) {
        throw new Error("Context used outside provider");
    }

    return context;
};

export default RegistrationSpecsProvider;
